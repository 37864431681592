import styles from "../Projects/Projects.module.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBtn = () => (
  <button type="submit" className={styles.searchBtn} aria-label="Search button">
    <FontAwesomeIcon
      icon={faSearch}
      size={"2x"}
      className={styles.searchIcon}
    />
  </button>
);

export default SearchBtn;
