import styles from "./Footer.module.css";
import { TiSocialGithub, TiSocialLinkedin } from "react-icons/ti";

const FooterSocial = () => {
  return (
    <div className={styles.socialIcons}>
      <a
        href="http://github.com/nevlaa"
        aria-label="Github"
        target="blank"
        rel="noopener noreferrer"
        className={styles.footerGithub}
      >
        <TiSocialGithub />
      </a>
      <a
        href="http://linkedin.com/in/scott-horsburgh"
        aria-label="Linkedin"
        target="blank"
        rel="noopener noreferrer"
        className={styles.footerLinkedin}
      >
        <TiSocialLinkedin />
      </a>
    </div>
  );
};

export default FooterSocial;
