import { faJsSquare, faReact } from "@fortawesome/free-brands-svg-icons";
import {
  faFileCode,
  faEnvelope,
  faComments,
  faLightbulb,
  faEye,
  faHandshake,
  faCompass,
} from "@fortawesome/free-regular-svg-icons";
import {
  faServer,
  faSearch,
  faCodeBranch,
  faUsers,
  faBusinessTime,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faFileCode,
  faEnvelope,
  faComments,
  faLightbulb,
  faEye,
  faHandshake,
  faCompass,
  faJsSquare,
  faServer,
  faSearch,
  faCodeBranch,
  faUsers,
  faBusinessTime,
  faDatabase,
  faReact
);
