/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

function useProjects() {
  const [projects, setProjects] = useState([]);
  const [fetchStatus, setFetchStatus] = useState("");

  const fetchProjects = async () => {
    setFetchStatus("Getting Projects from Github...");
    let data = await fetch("https://api.github.com/users/nevlaa/repos");
    if (!data.ok) {
      throw new Error("Request failed.");
    }
    const newData = await data.json();
    const projectData = newData.map((project) => {
      const color = "#".concat(
        Math.floor(Math.random() * 16777215).toString(16)
      );
      const { id, name, description, language, html_url } = project;
      return {
        id,
        name,
        description,
        language: language.toLowerCase(),
        projectLink: html_url,
        color,
      };
    });
    setProjects(projectData);
    setFetchStatus("");
  };

  useEffect(() => {
    if (projects.length === 0) fetchProjects();
  }, [projects]);
  return projects;
}

export default useProjects;
