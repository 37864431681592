import styles from "./Footer.module.css";
const FooterAuthor = ({ author, city }) => {
  function Copyright() {
    return (
      <>
        {" © "}
        {new Date().getFullYear()}
        {"."}
      </>
    );
  }

  return (
    <div className={styles.footerAuthor}>
      <span> {author}</span>
      <span>{city}</span>
      <Copyright />
      {/* <p className={styles.footerLinks}>
        <a href="https://www.freepik.com/vectors/people">
          People vector created by rawpixel.com - www.freepik.com
        </a>
      </p> */}
    </div>
  );
};

export default FooterAuthor;
