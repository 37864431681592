import styles from "./ContactForm.module.css";

const ContactMessage = ({ email }) => {
  return (
    <div className={styles.contactMessageContainer}>
      <h2>Get in touch.</h2>
      <h1>
        If you have a project in mind, let's get in touch and work together.
      </h1>
      <p>
        I'm available for interesting work or projects. Big or small. You can
        contact me using the form below or by emailing {email}.
      </p>
    </div>
  );
};

export default ContactMessage;
