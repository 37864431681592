import styles from "./Projects.module.css";

const SingleGithubProject = ({
  name,
  projectLink,
  description,
  language,
  color,
  setSearchWord,
}) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardBody}>
        <div className={styles.ptitle}>
          <a
            href={projectLink}
            className={styles.p4link}
            target="blank"
            aria-label="project link"
            rel="noopener noreferrer"
          >
            <span onClick={() => setSearchWord("")}>{name}</span>
          </a>
        </div>
        <div className={styles.projectInfo}>
          <p className={styles.p3}>{description}</p>
        </div>
        <div className={styles.projectCardFooter}>
          <p>
            <span
              className={styles.circle}
              style={{ background: color }}
            ></span>
            <span>{language}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleGithubProject;
