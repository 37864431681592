import React from "react";
import SearchBtn from "./SearchBtn";
import styles from "../Projects/Projects.module.css";

const SearchBar = ({ searchWord, handleChange }) => {
  return (
    <div className={styles.searchBarForm}>
      <label htmlFor="textSearch" />
      <input
        type="text"
        id="textSearch"
        onChange={handleChange}
        className={styles.searchBar}
        value={searchWord}
        placeholder="Search language..."
      />
      <SearchBtn />
    </div>
  );
};
export default SearchBar;
