import { Link } from "react-router-dom";

const FooterLinks = ({ link, name, alt }) => {
  return (
    <Link to={link} alt={alt}>
      {name}
    </Link>
  );
};

export default FooterLinks;
