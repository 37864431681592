import styles from "./ContactForm.module.css";

function ContactForm(props) {
  const {
    handleSubmit,
    onInputChange,
    setShow,
    status,
    fullname,
    email,
    message,
  } = props;

  return (
    <form
      id="contactForm"
      className={styles.formContainer}
      name="contactform"
      onSubmit={handleSubmit}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contactform" />

      <label htmlFor="fullname">Name:</label>
      <input
        type="text"
        id="fullname"
        name="fullname"
        value={fullname}
        onChange={onInputChange}
        placeholder="Full name"
        autoComplete="new-text-fullName"
        required
      />
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={onInputChange}
        placeholder="name@email.com"
        autoComplete="new-user-email-address"
        required
      />
      <label htmlFor="message">Message:</label>
      <textarea
        id="message"
        name="message"
        value={message}
        onChange={onInputChange}
        rows="10"
        cols="50"
        placeholder="Message"
        required
      ></textarea>
      <button type="submit" onClick={() => setShow(true)}>
        {status}
      </button>
    </form>
  );
}

export default ContactForm;
