/* eslint-disable no-unused-vars */
import data from "./data/featuredProjects";
import marvelcharacters from "../../images/marvelcharacters.gif";
import blogpost from "../../images/blogpost.gif";
import chatapp from "../../images/chatapp.gif";
import styles from "./SingleProjects.module.css";
import { Link } from "react-router-dom";

const SingleProject = ({ match }) => {
  const projectId = match.params.id;
  const project = data.find((proj) => proj.title === projectId);
  const lowerCaseProjectTitle = project.title.replace(/ /g, "").toLowerCase();
  let projectData;
  console.log(projectId);
  console.log(lowerCaseProjectTitle);
  const handleSingleProjectImages = (image) => {
    switch (image) {
      case "marvelcharacters":
        return (
          <img
            width="100%"
            height="100%"
            src={`${marvelcharacters}`}
            alt="Project"
          />
        );
      case "blogpost":
        return (
          <img width="100%" height="100%" src={`${blogpost}`} alt="Project" />
        );
      case "chatapp":
        return (
          <img width="100%" height="100%" src={`${chatapp}`} alt="Project" />
        );
      default:
        break;
    }
  };

  if (project) {
    projectData = (
      <div className={styles.singleProjectContainer}>
        <div className={styles.imageContainer}>
          {handleSingleProjectImages(lowerCaseProjectTitle)}
        </div>
        <div className={styles.projectDetails}>
          <div className={styles.singleProjectTitle}>
            <h1> {project.title} </h1>
          </div>
          <div className={styles.singleProjectDesc}>
            <p>{project.description}</p>
            <p>{project.subDescription}</p>
          </div>
          <div className={styles.singleProjectLinks}>
            <a
              href={project.link}
              target="blank"
              className={styles.singleProjectLink}
            >
              View Project
            </a>
            <a
              href={project.github}
              target="blank"
              className={styles.singleProjectLink}
            >
              Source Code
            </a>
          </div>
          <div className={styles.singleProjectLinks}>
            <Link to="/projects" className={styles.singleProjectBackLink}>
              Go Back
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    projectData = (
      <div style={{ margin: "0 auto" }}>
        <h1> Opps... Looks like this project isn't built yet! </h1>
      </div>
    );
  }
  return <> {projectData} </>;
};

export default SingleProject;
