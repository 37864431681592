const ModalContent = ({ fullname }) => {
  return (
    <>
      <div>
        <h1>Thank you for getting in touch {fullname}!</h1>
        <p>
          I appreciate you contacting me and I will be in touch with you as soon
          as possible!
        </p>{" "}
        <p>Have a great day!</p>
      </div>
    </>
  );
};

export default ModalContent;
