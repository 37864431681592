import styles from "./Modal.module.css";

const Modal = ({ children, show, clearDetails }) => {
  const content = show && (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <button
          className={styles.modalClose}
          type="button"
          onClick={() => clearDetails()}
        >
          Close
        </button>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );

  return content;
};

export default Modal;
