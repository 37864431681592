import styles from "./Footer.module.css";
import { menuLinks } from "../Navbar/menuLinks";
import Author from "./FooterAuthor";
import FooterLinks from "./FooterLinks";
import FooterSocial from "./FooterSocial";

const Footer = () => {
  return (
    <section className={styles.footerSection}>
      <div className={styles.footerInfo}>
        <div className={styles.footerLinks}>
          {menuLinks.map((link) => {
            const { id, name, url, alt } = link;
            return <FooterLinks key={id} name={name} link={url} alt={alt} />;
          })}
        </div>
        <Author author="Scott Horsburgh" city="Boise, ID" />
        <FooterSocial />
      </div>
    </section>
  );
};

export default Footer;
