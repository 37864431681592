const featuredProjectData = [
  {
    id: 1,
    title: "Marvel Characters",
    category: "Javascript",
    tech: "React / Redux / Marvel api / Tachyons",
    link: "https://marvelcharacters-redux.netlify.app",
    github: "https://github.com/Nevlaa/marvelCharacters-redux",
    description: `Search Marvel characters from the marvel api built with React and Redux. 
                  I used this project to learn Redux and use an api like the Marvel api to read the documentation 
                  and get familiar with criterias for example the Attribution, rate limits and linking.`,
    subDescription: `There were two dependencies I used with this project with Redux which were redux-logger and redux-thunk. Redux-logger 
                  gave me a way to get a better idea of what's happening with Redux and since I was using asyc fetching I needed redux-thunk middleware
                  so that my action creator returns a function for an asynchronous dispatch. One main feature I would like to add is pagination
                  to fetch all characters and to filter out those without images.`,
  },
  {
    id: 2,
    title: "Blog Post",
    category: "Javascript",
    tech: "EJS template Engine / Bootstrap 5 / Nodejs / MongoDB",
    link: "https://blog-ejs-express-mongoosedb.herokuapp.com/",
    github: "https://github.com/Nevlaa/ejs-blog-post",
    description: `A daily journal where you can share post you have created with others on the home page using EJS template engine on the frontend and Expressjs to handle crud operations with Mongoose 
      connecting to MongoDB Atlas to store all the post on the backend. Some of the dependencies used in the project are bodyParser and dotenv 
      to handle the environment variables used for the db connection.`,
    subDescription: `
        This project is deployed on heroku so initially it might take a few
        seconds for the dynos to restart if inactive but I plan to fix that by seperating the 
      frontend and backend using seperate services for faster load times and better user experience. This is a project I would like to replicate
      with React native adding additonal features like notifications when a user made a new post`,
  },
  {
    id: 3,
    title: "Chat App",
    category: "Javascript",
    tech: "React / Socket.io / Node / Express",
    link: "https://compassionate-poincare-0515fa.netlify.app",
    github: "https://github.com/Nevlaa/chatapp",
    description: `This is a fun chat app project that I plan on adding additional features and making the UI better and really started this project
    wanting to get exposed to socket.io using web sockets to see how it worked transfering data real time. The frontend is built with React and
     the backend is node , express, socket.io, router with custom middleware to add users, remove users, get users or users in the room and cors to handle
     the issue of cors policy access from another location.`,
    subDescription: `I have this project split where the client side is hosted on netlify and the server side is hosted on heroku. This is another
    project that I will convert using React Native adding notifications, a sidebar on larger screens and although you can do an emoji :) I'll 
    read up more on a easier way when the time comes either coding it or adding a npm package.`,
  },
];

export default featuredProjectData;
