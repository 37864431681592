import React from "react";
import styles from "./Projects.module.css";
import SingleProject from "./SingleProject";
import projectData from "./data/featuredProjects";
import { Link, Route, Switch } from "react-router-dom";

function FeaturedProject() {
  return (
    <React.Fragment>
      <div className={styles.projectWrapper}>
        {projectData.map((featureProject) => {
          const { id, title, tech } = featureProject;
          return (
            <div key={id} className={styles.projectContainer}>
              <div className={styles.projectDescription}>
                <span className={styles.featuredProjectTitle}>{title}</span>
                <p className={styles.featuredProjectDesc}>{tech}</p>
                <div className={styles.sourceCodeContainer}>
                  <Link
                    to={`projects/${title}`}
                    className={`${styles.sourceCodeBtn} ${styles.featuredLink}`}
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
        <Switch>
          <Route path={`projects/:id`}>
            <SingleProject />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default FeaturedProject;
