import React, { useState, useEffect } from "react";
import useWindowSize from "../../../utils/UseWindowSize";
import { NavLink } from "react-router-dom";
import styles from "./Navbar.module.css";
import { menuLinks } from "./menuLinks";
import { faBars, faTimes, faHSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = ({ match }) => {
  const windowSize = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const showMobile = showMenu
    ? `${styles.navMenu} ${styles.active}`
    : `${styles.navMenu}`;
  const closeMobile = `${styles.navMenu}`;

  const handleToggle = (checkShow) => {
    if (windowSize.width < 600) {
      setShowMenu(checkShow);
      if (!showMenu) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "scroll";
      }
    }
  };

  useEffect(() => {
    function handleMobileCollapse() {
      if (windowSize.width > 600) {
        setShowMenu(false);
        document.body.style.overflow = "scroll";
      }
    }
    window.addEventListener("resize", handleMobileCollapse);
    return () => window.removeEventListener("resize", handleMobileCollapse);
  }, [windowSize, showMenu]);

  return (
    <nav className={styles.NavbarItems}>
      <NavLink to="/" className={styles.navbarLogo}>
        <FontAwesomeIcon icon={faHSquare} style={{ color: "#1f6feb" }} />
        <span>orsburgh</span>
      </NavLink>
      <div className={styles.menuIcon} onClick={() => handleToggle(!showMenu)}>
        {showMenu ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </div>
      <div
        className={`${showMobile} ${windowSize.width > 600 && closeMobile} `}
      >
        {menuLinks.map((link) => {
          const { id, name, url, alt } = link;
          return (
            <NavLink
              key={id}
              to={url}
              alt={alt}
              className={
                url === "/contact"
                  ? `${styles.contactLink}`
                  : `${styles.navLinks}`
              }
              onClick={() => handleToggle(!showMenu)}
              activeClassName={styles.activeLink}
            >
              {name}
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
