import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

// Resets each link / path switch to start at top of page
//Without this the page will switch to next page at same window position
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default withRouter(ScrollToTop);
