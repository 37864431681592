import { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import ContactForm from "../components/ContactForm/ContactForm";
import ContactMessage from "../components/ContactForm/ContactMessage";
import Modal from "../components/Modal/Modal";
import ModalContent from "../components/Modal/ModalContent";

function Contact() {
  const contactEmail = "shorsburgh19@gmail.com";
  const initialDetails = {
    fullname: "",
    email: "",
    message: "",
  };

  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("Get In Touch");
  const [{ fullname, email, message }, setDetails] = useState(initialDetails);
  const [formStatus, setFormStatus] = useState(false);

  const clearDetails = () => {
    setDetails({ ...initialDetails });
    setShow(false);
    setFormStatus(false);
    <Route exact path="/">
      {!formStatus ? <Redirect to="/" /> : null}
    </Route>;
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    let details = {
      fullname,
      email,
      message,
    };
    try {
      setStatus("Sending...");
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contactform", ...details }),
      });
      setFormStatus(true);
      setStatus("Get In Touch");
    } catch (error) {
      alert(error);
    }
    e.preventDefault();
  };

  return (
    <>
      <ContactMessage email={contactEmail} />
      {formStatus && (
        <Modal show={show} clearDetails={clearDetails}>
          <ModalContent fullname={fullname} />
        </Modal>
      )}
      <ContactForm
        handleSubmit={handleSubmit}
        onInputChange={onInputChange}
        setShow={setShow}
        status={status}
        fullname={fullname}
        email={email}
        message={message}
      />
    </>
  );
}

export default Contact;
