import Banner from "../components/Banner/Banner";

function Home() {
  return (
    <>
      <Banner />
    </>
  );
}
export default Home;
