import NavBar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import styles from "./Layout.module.css";

export default function Layout({ children }) {
  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <NavBar />
      </header>
      <main className={styles.content}>{children}</main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
}
