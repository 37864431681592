import React, { useState, useEffect } from "react";
import useProjects from "../utils/useProjects";
import styles from "../components/Projects/Projects.module.css";
import FeaturedProject from "../components/Projects/FeaturedProject";
import GithubButton from "../components/Projects/GithubButton";
import SearchBar from "../components/SearchBar/searchBar";
import SingleGithubProject from "../components/Projects/SingleGithubProject";

const Projects = React.memo(() => {
  const fetchedProjects = useProjects();
  const [searchWord, setSearchWord] = useState("");
  const [filterDisplay, setFilterDisplay] = useState([]);

  const handleChange = (e) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    const filteredProject = fetchedProjects.filter((project) =>
      project.language.includes(searchWord.toLowerCase())
    );
    setFilterDisplay(filteredProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, fetchedProjects]);

  return (
    <section className={styles.projects}>
      <div className={styles.projectsContainer}>
        <p className={styles.projectsContainerTitle}>
          School <code>{"</>"}</code> Github Projects{" "}
        </p>
        <div className={styles.projectsContainerDescription}>
          <p>
            These are some small projects from school and in between school.
            Learning how to solve issues that came up by reading documentation,
            logging or other resources is one of the greatest benefits gained
            while building these small projects.
          </p>
        </div>
        <GithubButton />
        <FeaturedProject />
        <div className={styles.searchBarContainer}>
          <SearchBar handleChange={handleChange} searchWord={searchWord} />
        </div>
        <div className={styles.mainGithubContainer}>
          {filterDisplay.map((project) => (
            <SingleGithubProject
              key={project.id}
              name={project.name}
              description={project.description}
              language={project.language}
              projectLink={project.projectLink}
              color={project.color}
              setSearchWord={setSearchWord}
            />
          ))}
        </div>
      </div>
    </section>
  );
});

export default Projects;
