export const menuLinks = [
  {
    id: "1",
    name: "Home",
    url: "/",
    alt: "Home",
  },
  {
    id: "2",
    name: "Projects",
    url: "/projects",
    alt: "Projects",
  },
  {
    id: "3",
    name: "About",
    url: "/about",
    alt: "About",
  },
  {
    id: "4",
    name: "Contact",
    url: "/contact",
    alt: "Contact",
  },
];
