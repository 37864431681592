import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "../src/components/Layout/Layout";
import Home from "../src/Pages/home";
import ScrollToTop from "../src/utils/ScrollToTop";

import Projects from "../src/Pages/projects";
import Contact from "../src/Pages/contact";
import NotFoundPage from "../src/Pages/error";
import SingleProject from "./components/Projects/SingleProject";
const aboutPagePromise = import("../src/Pages/about");
const About = lazy(() => aboutPagePromise);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Suspense fallback={<p>Loading...</p>}>
            <Route exact path="/" component={Home} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/projects/:id" component={SingleProject} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
          </Suspense>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Layout>
    </Router>
  );
}
export default App;
