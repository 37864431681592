import styles from "./Banner.module.css";
import { Link } from "react-router-dom";
function Banner() {
  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={styles.title}>
          <h1>Hey, I'm Scott </h1>
          <h2>
            A web developer located in Boise, ID. My passion is to create
            websites for small businesses that fits their unique needs adding
            value for the customer and growth for the business.
          </h2>
          <div className={styles.btnContainer}>
            <Link to="/about" className={styles.aboutBtn}>
              About Me
            </Link>
            <Link to="/contact" className={styles.getInTouchBtn}>
              ✉ Get In Touch
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
