import styles from "./Projects.module.css";

const GithubButton = () => {
  return (
    <div className={styles.githubRepoButtonContainer}>
      <button className={styles.githubRepoButton}>
        <a
          href="https://github.com/Nevlaa"
          target="blank"
          aria-label="Github repository"
          rel="noopener noreferrer"
        >
          <span>{">"}</span>
          <span>{">"}</span>
          <span>{">"}</span> Github Repository
        </a>
      </button>
    </div>
  );
};

export default GithubButton;
